import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

interface DrawerProps {
    open: boolean;
}

const DrawerContainer = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    backdrop-filter: blur(4px);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
    height: 200vh;
    padding: 20px;
    width: 255px;
`;

const DrawerLogo = styled.div`
    align-items: "center";
    justify-content: flex-start;
    padding: 50px 10px;
    width: 150px;
`;

const DrawerNav = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

const DrawerNavLink = styled(Link)`
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: ${(props) => props.theme.colors.alternative};
    text-transform: uppercase;
    text-decoration: none;
`;

export const Drawer: React.FC<DrawerProps> = ({ open = false }) => {
    return (
        <DrawerContainer>
            <DrawerLogo></DrawerLogo>
            <DrawerNav>
                <DrawerNavLink to="/">empreendimento</DrawerNavLink>
                <DrawerNavLink to="/vale-dos-vinhedos">
                    vale dos vinhedos
                </DrawerNavLink>
                <DrawerNavLink to="/terroir">terroir significado</DrawerNavLink>
                <DrawerNavLink to="/pay-per-use">pay per use</DrawerNavLink>
            </DrawerNav>
        </DrawerContainer>
    );
};
