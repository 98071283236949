import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Hamburger from "hamburger-react";
import React, { useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../styledResponsive";
import { Drawer } from "./Drawer";

const padding = "24px 0";

export const Nav = styled.nav`
    align-items: center;
    display: none;
    ${breakpoints("display", ["none", "none", "flex", "flex"])};
    flex-direction: row;
    height: 100%;
    justify-content: center;
    width: 100%;
`;
export const NavLink = styled(Link)`
    color: ${(props) => props.theme.colors.alternative};
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: 100;
    padding: ${padding};
    padding-left: 24px;
    padding-right: 24px;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    height: 100%;
    transition: 1s all ease-in-out;
    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
    }
`;

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.colors.primaryTransparent};
    backdrop-filter: blur(4px);
    flex-direction: row;
    display: flex;
    height: 80px;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    ${breakpoints("width", ["100%", "100%", "960px", "1200px"])};
    justify-content: center;
    align-items: center;
    position: relative;
`;

const HamburguerContainer = styled.div`
    position: absolute;
    top: 50%;
    display: block;
    ${breakpoints("display", ["block", "block", "none", "none"])};
    transform: translateY(-50%);
    right: 10px;
`;

export const TopNav: React.FC = () => {
    const [toggled, setToggled] = useState(false);
    return (
        <Wrapper>
            <Container>
                <HamburguerContainer>
                    <Hamburger
                        color="white"
                        toggled={toggled}
                        onToggle={() => setToggled(!toggled)}
                    />
                </HamburguerContainer>
                <Nav>
                    <NavLink to="/">empreendimento</NavLink>
                    <NavLink to="/vale-dos-vinhedos">vale dos vinhedos</NavLink>
                    <NavLink to="/terroir">terroir significado</NavLink>
                    <NavLink to="/pay-per-use">pay per use</NavLink>
                </Nav>
            </Container>
            <div
                style={{
                    left: toggled ? 0 : -355,
                    position: "fixed",
                    top: 0,
                    transform: "translateZ(0.1px)",
                    transition: "all 0.3s ease-in-out",
                    zIndex: 10,
                }}
            >
                <Drawer open={toggled} />
            </div>
        </Wrapper>
    );
};
