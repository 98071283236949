import axios from "axios";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { breakpoints } from "../styledResponsive";
import { InputField, TextArea } from "./FormFields";

interface FormValues {
    name: string;
    email: string;
    phone: string;
    message: string;
}

const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.alternative};
    width: 100%;
    margin: 44px  auto;
    width: 325px;
    padding: 16px;
    border-radius: 4px;
    text-align: center;
`;

const SuccessTitle = styled.h4`
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 12px;
`;

const SuccessText = styled.p`
    font-size: 18px;
`;

const LeftCol = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${breakpoints("width", ["100%", "100%", "40%", "40%"])};
`;

const RightCol = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${breakpoints("width", ["100%", "100%", "60%", "60%"])};
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    ${breakpoints("width", ["100%", "100%", "960px", "1200px"])};
`;

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${breakpoints("flex-direction", ["column", "column", "row", "row"])};
    width: 100%;
`;

const Button = styled.button`
    width: 100%;
    cursor: pointer;
    padding: 16px;
    font-size: 18px;
    text-transform: uppercase;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.alternative};
    border: none;
    border: 1px solid ${(props) => props.theme.colors.primaryTransparent};
    transition: all 0.5s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
`;

const Success = () => (
    <SuccessContainer>
        <SuccessTitle>Obrigado pelo contato!</SuccessTitle>
        <SuccessText>
            Em breve, nossa equipe entrará em contato com você
        </SuccessText>
    </SuccessContainer>
);

const FormText = styled.p`
    font-size: 24px;
    opacity: 0.6;
    padding: 40px 0;
    text-align: center;
`;

export const ContactForm: React.FC = () => {
    const [done, setDone] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>();

    const submit = async (values: FormValues) => {
        setIsSaving(true);
        try {
            const res = await axios.post(
                "https://www.terroirvinhedos.com.br/contact.php",
                values,
            );
            console.log(res);
            setDone(true);
            //@ts-ignore
            window.gtag('event', 'conversion', {'send_to': 'AW-10792954926/dhOcCJaBuf4CEK7YvZoo'});
        } catch (error) {
            alert(
                "Ocorreu um erro no envio do formulário, por favor, tente novamente",
            );
        }
        setIsSaving(false);
    };

    if (done) {
        return <Success />;
    }

    return (
        <Form onSubmit={handleSubmit(submit)}>
            <FormText>
                Quer saber mais? Preencha este breve formulário abaixo.
                <br />
                Em seguida, um de nossos consultores entrará em contato.
            </FormText>
            <FieldsContainer>
                <LeftCol>
                    <InputField
                        name="name"
                        register={register}
                        label="Nome*"
                        rules={{ required: true }}
                        errors={errors}
                        maxLength={50}
                    />
                    <InputField
                        name="email"
                        register={register}
                        label="E-mail*"
                        rules={{ required: true, email: true }}
                        errors={errors}
                    />
                    <InputField
                        name="phone"
                        register={register}
                        label="Telefone*"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </LeftCol>
                <RightCol>
                    <TextArea
                        name="message"
                        register={register}
                        label="Mensagem*"
                        rules={{ required: true }}
                        errors={errors}
                    />
                </RightCol>
            </FieldsContainer>
            <div style={{ padding: 20, width: "100%" }}>
                <Button>Enviar</Button>
            </div>
        </Form>
    );
};
