import { convertHexToRGBA } from "./utils/colors";
import { DefaultTheme } from "styled-components";
const primary = "#707768";

export const theme: DefaultTheme = {
    colors: {
        primary,
        text: "#1d1d1b",
        alternative: "#ffffff",
        primaryTransparent: convertHexToRGBA(primary, 50),
        primaryLessTransparent: convertHexToRGBA(primary, 80),
    },
    spacing: 4,
};
