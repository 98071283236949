import React from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../theme";

const Container = styled.div`
    padding: 20px;
    width: 100%;
`;

const FormGroup = styled.div<{ isFocused: boolean }>`
    background-color: #eee;
    border-bottom: 2px solid
        ${(props) => (props.isFocused ? props.theme.colors.primary : "#eee")};
    border-radius: 1px;
    height: 80px;
    padding: 20px;
    padding-bottom: 0;
    padding-top: 0;
    text-align: left;
    width: 100%;
`;

const ErrorMessage = styled.small`
    color: red;
`;

const TextAreaFormGroup = styled(FormGroup)`
    height: 320px;
`;

const Input = styled.input`
    font-size: 1.2rem;
    height: 40px;
    width: 100%;
    border: none;
    background-color: transparent;
    &:focus,
    /* &:focus-visible, */
    &:active {
        border: none;
        outline: none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
`;

const TextAreaInput = styled.textarea`
    background-color: transparent;
    border: none;
    font-size: 1rem;
    height: 240px;
    width: 100%;
    &:focus,
    &:active {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        border: none;
        box-shadow: none;
        outline-width: 0 !important;
        outline: none !important;
    }
`;

const Label = styled.label`
    display: block;
    margin-left: -10px;
    opacity: 0.5;
    padding: 10px;
    padding-bottom: 0;
    width: 100%;
`;

export const InputField = ({
    label,
    name,
    register,
    errors,
    type = "text",
    rules = {},
    disabled = false,
    helpText = null,
    maxLength = 50,
    placeholder = "",
}) => {
    let borderBottomColor = "#eee";

    const inputRef = useRef<HTMLInputElement>(null);

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        //@ts-ignore
        borderBottomColor = theme.colors.primary;
        setIsFocused(true);
        console.log({ borderBottomColor });
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleLabelClick = () => {
        // inputRef.current.focus();
    };

    return (
        <Container>
            <FormGroup isFocused={isFocused} onClick={handleLabelClick}>
                <Label htmlFor={name}>{label}</Label>
                <Input
                    ref={inputRef}
                    type={type}
                    className="form-control"
                    {...register(name, { ...rules })}
                    disabled={disabled}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    name={name}
                    id={name}
                    //@ts-ignore
                    required={rules.required ? "required" : undefined}
                />
                {errors && errors[name] ? (
                    <ErrorMessage>Preenchimento inválido.</ErrorMessage>
                ) : null}
            </FormGroup>
        </Container>
    );
};

export const TextArea = ({
    label,
    name,
    register,
    errors,
    type = "text",
    rules = {},
    disabled = false,
    helpText = null,
    maxLength = 50,
    placeholder = "",
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <Container>
            <TextAreaFormGroup isFocused={isFocused}>
                <Label htmlFor={name}>{label}</Label>
                <TextAreaInput
                    type={type}
                    {...register(name, { ...rules })}
                    disabled={disabled}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    rows={20}
                    name={name}
                    id={name}
                    //@ts-ignore
                    required={rules.required ? "required" : undefined}
                ></TextAreaInput>
                {errors && errors[name] ? (
                    <ErrorMessage>Preenchimento inválido.</ErrorMessage>
                ) : null}
            </TextAreaFormGroup>
        </Container>
    );
};
