import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { TopNav } from "../components/TopNav";
import { breakpoints } from "../styledResponsive";
import { theme } from "../theme";
import { ContactForm } from "./ContactForm";
import "react-image-lightbox/style.css";

const GlobalStyles = createGlobalStyle`
     * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    body {
        height: 100%;
        font-family: "Roboto", Arial, sans-serif;
    }
`;

const Wrapper = styled.div`
    display: grid;
    height: 100%;
    position: relative;
`;

interface LayoutProps {
    children: any;
}

const Footer = styled.footer`
    flex-direction: column;
    align-items: center;
    display: flex;
    height: 300px;
    justify-content: center;
    width: 100%;
`;

const LexLogo = styled.a`
    display: block;
    width: 130px;
`;

const Text = styled.p`
    width: 325px;
    text-align: center;
    font-size: 13px;
    ${breakpoints("width", ["300px", "300px", "600px", "600px"])};
    margin-bottom: 36px;
    margin-top: 36px;
`;

export const Layout: React.FC<LayoutProps> = ({ children }) => (
    <Wrapper>
        <GlobalStyles />
        <ThemeProvider theme={theme}>
            <TopNav />
            {children}
            <ContactForm />
            <Footer>
                <LexLogo href="https://www.leximoveis.com.br/" target="_blank">
                    <StaticImage
                        src="../images/logo-lex.png"
                        alt="Lex Empreendimentos Imobiliários"
                    />
                </LexLogo>
                <Text>
                    Incorporação registrada na Matrícula n° 35.429 do Livro 2 -
                    RG do Registro de Imóveis de Garibaldi – RS. Imagens
                    meramente ilustrativas. | Lex Empreendimentos Imobiliários
                    Ltda. - CRECI 782-J
                </Text>
            </Footer>
        </ThemeProvider>
    </Wrapper>
);
